import { Node } from '@tiptap/core';

import { capitalizeFirstLetterInStringOrList } from '../../utils/string';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    addDash: {
      addDash: () => ReturnType;
    };
  }
}

// https://stackoverflow.com/a/62270232
export const AddDash = Node.create({
  name: 'addDash',

  addCommands() {
    return {
      addDash:
        () =>
        ({ tr, state }) => {
          const { selection } = tr;
          const { empty } = selection;

          let addedDashCount = 0;

          if (!empty) {
            state.doc.nodesBetween(
              selection.from,
              selection.to,
              (node, position) => {
                // we only processing text, must be a selection
                if (
                  !node.isTextblock ||
                  selection.from === selection.to ||
                  node.content.size === 0
                )
                  return;

                // calculate the section to replace
                const startPosition = Math.max(
                  position + 1 + addedDashCount, // Add addedPointCount to the position to account for the point we're adding
                  selection.from + addedDashCount // Add addedPointCount to the position to account for the point we're adding
                );
                const endPosition = Math.min(
                  position + node.nodeSize + addedDashCount, // Add addedPointCount to the position to account for the point we're adding
                  selection.to + addedDashCount // Add addedPointCount to the position to account for the point we're adding
                );

                // grab the content
                const substringFrom = Math.max(
                  0,
                  selection.from - position - 1
                );
                const substringTo = Math.max(0, selection.to - position - 1);

                // set the casing
                let updatedText = capitalizeFirstLetterInStringOrList(
                  node.textContent.substring(substringFrom, substringTo)
                );

                // add the point if necessary
                if (!updatedText.startsWith('-')) {
                  updatedText = `- ${updatedText}`;
                  addedDashCount = addedDashCount + 2;
                }

                // Fix: RangeError: Empty text nodes are not allowed
                if (updatedText.length === 0) return;

                const textNode = state.schema.text(updatedText, node.marks);

                // replace
                tr = tr.replaceWith(startPosition, endPosition, textNode);
              }
            );
          }

          return true;
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Shift-1': () => this.editor.commands.addDash(),
    };
  },
});
