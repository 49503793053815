import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@appchoose/toast';

import { useCategoryTreeQuery } from '../../types/generated';

export const useCategories = () => {
  const { t } = useTranslation();

  const categories = useCategoryTreeQuery(
    {},
    {
      refetchOnMount: false,
      select: (result) => result?.categoryTree,
    }
  );

  useEffect(() => {
    if (categories.error) {
      toast.error(t('sale_page.category_error'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories.error]);

  return categories;
};
