type AddPointProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const AddPoint: React.FC<AddPointProps> = ({
  ...props
}: AddPointProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.17928 20C3.52798 20 3 19.5104 3 18.9065C3 18.7503 3.01889 18.6362 3.09924 18.4667L8.47078 4.63092C8.79105 3.78969 10.209 3.7897 10.5292 4.63092L15.9008 18.4667C15.9811 18.6362 16 18.7503 16 18.9065C16 19.5104 15.472 20 14.8207 20C14.3659 20 13.989 19.7513 13.8222 19.3961C13.3857 18.4667 12.9604 17.4256 12.5895 16.419H6.41045C6.03958 17.4256 5.61432 18.4667 5.17781 19.3961C5.01098 19.7513 4.63405 20 4.17928 20ZM11.8224 14.3663L9.5 8.10892L7.17759 14.3663H11.8224Z"
        fill="currentColor"
      />
      <circle cx="19.5" cy="18.5" r="1.5" fill="currentColor" />
    </svg>
  );
};
