import { useAuth } from '../api/auth';

export const useFetchData = <TData, TVariables>(
  query: string,
  options?: RequestInit['headers']
): ((variables?: TVariables) => Promise<TData>) => {
  const { getIdToken } = useAuth();

  return async (variables?: TVariables) => {
    const res = await fetch(import.meta.env.REACT_APP_API_URL as string, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Apollographql-Client-Name': 'meusnidus-v2',
        Authorization: await getIdToken(),
        ...options,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0] as Error;

      throw new Error(message);
    }

    return json.data as TData;
  };
};
