import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { pageView } from '../../api/analytics';
import { useDocumentTitle } from '../../hooks/use-document-title';
import { titleWrapper } from '../../utils/title';

export const UnauthorizedPage: React.FC = () => {
  const { t } = useTranslation();

  useDocumentTitle(t('unauthorized_page.url_title'), {
    wrapper: titleWrapper,
  });

  useEffect(() => {
    pageView('Unauthorized');
  }, []);

  return (
    <div className="flex w-full items-center justify-center">
      {t('access_denied')}
    </div>
  );
};
