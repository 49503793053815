import { Node } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    trimStart: {
      trimStart: () => ReturnType;
    };
  }
}

// https://stackoverflow.com/a/62270232
export const TrimStart = Node.create({
  name: 'trimStart',

  addCommands() {
    return {
      trimStart:
        () =>
        ({ tr, state }) => {
          const { selection } = tr;
          const { empty } = selection;

          let removeWhiteSpaceCount = 0;

          if (!empty) {
            state.doc.nodesBetween(
              selection.from,
              selection.to,
              (node, position) => {
                // we only processing text, must be a selection
                if (
                  !node.isTextblock ||
                  selection.from === selection.to ||
                  node.content.size === 0
                )
                  return;

                // calculate the section to replace
                const startPosition = Math.max(
                  position + 1 + removeWhiteSpaceCount, // Add addedPointCount to the position to account for the point we're adding
                  selection.from + removeWhiteSpaceCount // Add addedPointCount to the position to account for the point we're adding
                );
                const endPosition = Math.min(
                  position + node.nodeSize + removeWhiteSpaceCount, // Add addedPointCount to the position to account for the point we're adding
                  selection.to + removeWhiteSpaceCount // Add addedPointCount to the position to account for the point we're adding
                );

                // grab the content
                const substringFrom = Math.max(
                  0,
                  selection.from - position - 1
                );
                const substringTo = Math.max(0, selection.to - position - 1);

                let updatedText = node.textContent.substring(
                  substringFrom,
                  substringTo
                );

                const whiteSpaceCharactersCount = updatedText.search(/\S/);

                // remove space if necessary
                if (whiteSpaceCharactersCount > -1) {
                  updatedText = updatedText.substring(
                    whiteSpaceCharactersCount,
                    updatedText.length
                  );
                  removeWhiteSpaceCount -= whiteSpaceCharactersCount;
                }

                if (updatedText.length === 0) return;

                const textNode = state.schema.text(updatedText, node.marks);

                // replace
                tr = tr.replaceWith(startPosition, endPosition, textNode);
              }
            );
          }

          return true;
        },
    };
  },
});
