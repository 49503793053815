import { Node } from '@tiptap/core';

import { capitalizeFirstLetter } from '../../utils/string';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    addPoint: {
      addPoint: () => ReturnType;
    };
  }
}

// https://stackoverflow.com/a/62270232
export const AddPoint = Node.create({
  name: 'addPoint',

  addCommands() {
    return {
      addPoint:
        () =>
        ({ tr, state }) => {
          const { selection } = tr;
          const { empty } = selection;

          let addedPointCount = 0;

          if (!empty) {
            state.doc.nodesBetween(
              selection.from,
              selection.to,
              (node, position) => {
                // we only processing text, must be a selection
                if (
                  !node.isTextblock ||
                  selection.from === selection.to ||
                  node.content.size === 0
                )
                  return;

                // calculate the section to replace
                const startPosition = Math.max(
                  position + 1 + addedPointCount, // Add addedPointCount to the position to account for the point we're adding
                  selection.from + addedPointCount // Add addedPointCount to the position to account for the point we're adding
                );
                const endPosition = Math.min(
                  position + node.nodeSize + addedPointCount, // Add addedPointCount to the position to account for the point we're adding
                  selection.to + addedPointCount // Add addedPointCount to the position to account for the point we're adding
                );

                // grab the content
                const substringFrom = Math.max(
                  0,
                  selection.from - position - 1
                );
                const substringTo = Math.max(0, selection.to - position - 1);

                // set the casing
                let updatedText = capitalizeFirstLetter(
                  node.textContent.substring(substringFrom, substringTo)
                );

                // add the point if necessary
                const trimmedUpdatedText = updatedText.trimEnd();
                if (
                  !trimmedUpdatedText.endsWith('.') &&
                  !trimmedUpdatedText.endsWith(',') &&
                  !trimmedUpdatedText.endsWith('?') &&
                  !trimmedUpdatedText.endsWith('!') &&
                  !trimmedUpdatedText.endsWith(':') &&
                  !trimmedUpdatedText.endsWith(';') &&
                  trimmedUpdatedText.length > 0
                ) {
                  if (trimmedUpdatedText.length !== updatedText.length) {
                    const countWhitespaceCharacters =
                      updatedText.length - trimmedUpdatedText.length;
                    addedPointCount -= countWhitespaceCharacters;
                    updatedText = `${trimmedUpdatedText}.`;
                  } else {
                    updatedText = `${updatedText}.`;
                  }
                  addedPointCount++;
                }

                // Fix: RangeError: Empty text nodes are not allowed
                if (updatedText.length === 0) return;

                const textNode = state.schema.text(updatedText, node.marks);

                // replace
                tr = tr.replaceWith(startPosition, endPosition, textNode);
              }
            );
          }

          return true;
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Shift-2': () => this.editor.commands.addPoint(),
    };
  },
});
