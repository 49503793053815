import { Node } from '@tiptap/core';
import { AllSelection } from '@tiptap/pm/state';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    removeNewLines: {
      removeNewLines: () => ReturnType;
    };
  }
}

// https://stackoverflow.com/a/62270232
export const RemoveNewLines = Node.create({
  name: 'removeNewLines',

  addCommands() {
    return {
      removeNewLines:
        () =>
        ({ tr, state }) => {
          const { selection } = tr;
          const { empty } = selection;

          let selectionFrom = selection.from;
          let selectionTo = selection.to;

          // if selection is empty, select all
          if (empty) {
            tr = tr.setSelection(new AllSelection(state.doc));
            selectionFrom = tr.selection.from;
            selectionTo = tr.selection.to;
          }

          let movedPosition = 0;
          let isPreviousNewLine = false;
          state.doc.nodesBetween(
            selectionFrom,
            selectionTo,
            (node, position) => {
              if (node.isTextblock && node.textContent.trim().length === 0) {
                if (!isPreviousNewLine) {
                  isPreviousNewLine = true;
                } else {
                  // delete
                  tr = tr.delete(
                    position + movedPosition,
                    position + node.nodeSize + movedPosition
                  );

                  movedPosition = movedPosition - node.nodeSize;
                }
              } else {
                if (node.textContent.trim().length !== 0) {
                  isPreviousNewLine = false;
                }
              }
            }
          );

          return true;
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Shift-4': () => this.editor.commands.removeNewLines(),
    };
  },
});
