const emojis = [
  '😎',
  '😍',
  '😬',
  '😇',
  '😅',
  '🤗',
  '🤩',
  '😳',
  '👩',
  '👱',
  '🙅',
  '️🤳',
  '💃',
  '🕺',
  '🏃',
  '️👊',
  '✌️',
  '🤟',
  '👌',
  '✋',
  '💪',
  '🙏',
  '💍',
  '💄',
  '💋',
  '👄',
  '👅',
  '👂',
  '👃',
  '👣',
  '👁',
  '👀',
  '🧠',
  '🧥',
  '👚',
  '👕',
  '👖',
  '👔',
  '👗',
  '👙',
  '👘',
  '👠',
  '👡',
  '👢',
  '👞',
  '👟',
  '🧦',
  '🧤',
  '🧣',
  '🎩',
  '🧢',
  '👒',
  '🎓',
  '👑',
  '👝',
  '👛',
  '👜',
  '💼',
  '🎒',
  '👓',
  '🕶',
  '🌂',
  '🙈',
  '🐥',
  '🌱',
  '🌿',
  '🍃',
  '💐',
  '🌷',
  '🌹',
  '🥀',
  '🌺',
  '🌸',
  '🌼',
  '🌻',
  '✨',
  '⚡',
  '🔥',
  '🌈',
  '☀️',
  '️💧',
  '💦',
  '🍏',
  '🍎',
  '🍐',
  '🍊',
  '🍋',
  '🍌',
  '🍉',
  '🍇',
  '🍓',
  '🍈',
  '🍒',
  '🍑',
  '🍍',
  '🥥',
  '🥝',
  '🍅',
  '🍆',
  '🥑',
  '🥦',
  '🥒',
  '🌶',
  '🌽',
  '🥕',
  '🥐',
  '🍞',
  '🥖',
  '🧀',
  '🍳',
  '🥞',
  '🍦',
  '🍪',
  '🍯',
  '☕️',
  '🍻',
  '🥂',
  '🍷',
  '⚽',
  '🏖',
  '📸',
  '⏱',
  '⏰',
  '🕰',
  '⌛️',
  '💎',
  '💰',
  '🚿',
  '🛁',
  '🎁',
  '🎈',
  '🛍',
  '🔎',
  '❤️',
  '🧡',
  '💛',
  '💚',
  '💙',
  '💜',
  '🖤',
  '⚠️',
  '🇫🇷',
  '🇺🇸',
  '🇪🇸',
  '🇵🇹',
];

export default emojis;
