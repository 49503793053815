import { atom } from 'recoil';

export enum Pages {
  Home = 'home',
  SearchBrand = 'search-brand',
  SearchSale = 'search-sale',
  SetProductCategory = 'set-product-category',
  RenameCustomFieldColumn = 'rename-custom-field-column',
  RenameCustomFieldInput = 'rename-custom-field-input',
  SetupProducts = 'setup-products',
}

export type Page = {
  page: Pages;
  placeholder: string;
  badge?: string;
};

export const pagesCommandMenuState = atom<Pages[]>({
  key: 'pagesCommandMenuState',
  default: [Pages.Home],
});
export const isOpenCommandMenuState = atom<boolean>({
  key: 'isOpenCommandMenuState',
  default: false,
});
export const isSetupProductsActiveCommandMenuState = atom<boolean>({
  key: 'isSetupProductsActiveCommandMenuState',
  default: false,
});
export const selectedCustomFieldNameState = atom<string | null>({
  key: 'selectedCustomFieldNameState',
  default: null,
});
