type RemoveNewLinesProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const RemoveNewLines: React.FC<RemoveNewLinesProps> = (
  props: RemoveNewLinesProps
) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.999 3C14.999 2.44772 15.4468 2 15.999 2H19.9991C20.5513 2 20.9991 2.44772 20.9991 3V9C20.9991 9.55228 20.5513 10 19.9991 10H6.41348L6.70637 10.2929C7.0969 10.6834 7.0969 11.3166 6.70637 11.7071C6.31585 12.0976 5.68269 12.0976 5.29216 11.7071L3.29216 9.70711C2.90164 9.31658 2.90164 8.68342 3.29216 8.29289L5.29216 6.29289C5.68269 5.90237 6.31585 5.90237 6.70637 6.29289C7.0969 6.68342 7.0969 7.31658 6.70637 7.70711L6.41348 8H18.9991V4H15.999C15.4468 4 14.999 3.55228 14.999 3Z"
        fill="currentColor"
      />
      <path
        d="M6.70637 15.2929C7.0969 15.6834 7.0969 16.3166 6.70637 16.7071L6.41348 17H10.9993C11.5516 17 11.9993 17.4477 11.9993 18C11.9993 18.5523 11.5516 19 10.9993 19H6.41348L6.70637 19.2929C7.0969 19.6834 7.0969 20.3166 6.70637 20.7071C6.31585 21.0976 5.68269 21.0976 5.29216 20.7071L3.29216 18.7071C2.90164 18.3166 2.90164 17.6834 3.29216 17.2929L5.29216 15.2929C5.68269 14.9024 6.31585 14.9024 6.70637 15.2929Z"
        fill="currentColor"
      />
      <path
        d="M14.7064 14.2929C14.3159 13.9024 13.6827 13.9024 13.2922 14.2929C12.9016 14.6834 12.9016 15.3166 13.2922 15.7071L15.5851 18L13.2922 20.2929C12.9016 20.6834 12.9016 21.3166 13.2922 21.7071C13.6827 22.0976 14.3159 22.0976 14.7064 21.7071L16.9993 19.4142L19.2922 21.7071C19.6827 22.0976 20.3159 22.0976 20.7064 21.7071C21.0969 21.3166 21.0969 20.6834 20.7064 20.2929L18.4135 18L20.7064 15.7071C21.0969 15.3166 21.0969 14.6834 20.7064 14.2929C20.3158 13.9024 19.6827 13.9024 19.2922 14.2929L16.9993 16.5858L14.7064 14.2929Z"
        fill="currentColor"
      />
    </svg>
  );
};
