import { Extension } from '@tiptap/core';

export const OverrideEscape = Extension.create({
  name: 'OverrideEscape',
  addKeyboardShortcuts() {
    return {
      Escape: () => {
        this.editor.commands.blur();
        window.dispatchEvent(
          new CustomEvent('customKeyDown', { detail: 'Escape' })
        );
        return true;
      },
    };
  },
});
