import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '@appchoose/button';
import Logo from '@appchoose/logo';

import { pageView } from '../../api/analytics';
import { useAuth } from '../../api/auth';
import { useDocumentTitle } from '../../hooks/use-document-title';
import { titleWrapper } from '../../utils/title';

export const LoginPage: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useDocumentTitle(t('login_page.url_title'), {
    wrapper: titleWrapper,
  });

  useEffect(() => {
    pageView('Login');
  }, []);

  const from =
    (location.state as { from?: { pathname?: string } })?.from?.pathname ?? '/';

  return (
    <>
      <header className="my-16 text-center">
        {import.meta.env.REACT_APP_STAGE === 'production' ? (
          <Logo role="img" aria-label="Logo Choose" />
        ) : (
          <Logo appearance="white" role="img" aria-label="Logo Choose" />
        )}
      </header>
      <main className="text-center">
        <Button
          type="button"
          onClick={() =>
            auth.signin(() => {
              navigate(from, { replace: true });
            })
          }
        >
          {t('login_page.sign_in')}
        </Button>
      </main>
    </>
  );
};
