import { useEffect } from 'react';

export interface UseDocumentTitleOptions {
  /**
   * Function that processes title, useful to prefix or suffix the title.
   * @param title
   */
  wrapper: (title: string) => string;
}

/**
 * Sets title of the page.
 *
 * @param title Title to set, if wrapper option is set, it will be passed through wrapper function.
 * @param options Options object.
 */
export function useDocumentTitle(
  title: string,
  options: Partial<UseDocumentTitleOptions> = {}
): void {
  useEffect(() => {
    document.title = options.wrapper ? options.wrapper(title) : title;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, options.wrapper]);
}
