import { useEffect, useState } from 'react';

import { ChooseClaims, useAuth } from '../api/auth';

export const useUserClaims = () => {
  const [claims, setClaims] = useState<ChooseClaims | null>(null);
  const { getTokenResult } = useAuth();

  useEffect(() => {
    getTokenResult((tokenResult) => {
      setClaims(tokenResult.claims as any);
    });
  }, [getTokenResult]);

  return claims;
};
