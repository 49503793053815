import { useParams, useSearchParams } from 'react-router-dom';

import { useSuspenseBrandQuery } from '../../types/generated';

export const useBrand = () => {
  const { brandId } = useParams();
  const [searchParams] = useSearchParams();

  const brand = useSuspenseBrandQuery(
    { brandId: brandId ?? '' },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: searchParams.get('edit') !== 'true',
      refetchOnReconnect: searchParams.get('edit') !== 'true',
      retry: false,
      select: (result) => result.brand,
    }
  );

  return brand;
};
