type AddDashProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const AddDash: React.FC<AddDashProps> = ({ ...props }: AddDashProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1793 20C9.52798 20 9 19.5104 9 18.9065C9 18.7503 9.01889 18.6362 9.09924 18.4667L14.4708 4.63092C14.791 3.78969 16.209 3.7897 16.5292 4.63092L21.9008 18.4667C21.9811 18.6362 22 18.7503 22 18.9065C22 19.5104 21.472 20 20.8207 20C20.3659 20 19.989 19.7513 19.8222 19.3961C19.3857 18.4667 18.9604 17.4256 18.5895 16.419H12.4104C12.0396 17.4256 11.6143 18.4667 11.1778 19.3961C11.011 19.7513 10.6341 20 10.1793 20ZM17.8224 14.3663L15.5 8.10892L13.1776 14.3663H17.8224Z"
        fill="currentColor"
      />
      <rect x="3" y="10" width="6" height="2" rx="1" fill="currentColor" />
    </svg>
  );
};
