import { TFunction } from 'i18next';

export const formatPercentage = (value: number, locale: string) => {
  return Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(value);
};

export const formatPercentageRange = (
  percentageRange: { min: number; max: number } | null | undefined,
  locale: string,
  t: TFunction<'translation', undefined>
) => {
  if (!percentageRange) {
    return formatPercentage(0, locale);
  }

  if (percentageRange.max === percentageRange.min) {
    return formatPercentage(-percentageRange.min / 100, locale);
  }

  return t('sale_page.products.product_price_range', {
    min: formatPercentage(-percentageRange.min / 100, locale),
    max: formatPercentage(-percentageRange.max / 100, locale),
  });
};

export const formatInteger = (value: number, locale: string) => {
  return Intl.NumberFormat(locale, {
    maximumFractionDigits: 0,
  }).format(value);
};

export const formatIntegerRange = (
  integerRange: { min: number; max: number } | null | undefined,
  locale: string,
  t: TFunction<'translation', undefined>
) => {
  if (!integerRange) {
    return formatInteger(0, locale);
  }

  if (integerRange.max === integerRange.min) {
    return formatInteger(integerRange.min, locale);
  }

  return t('sale_page.products.product_price_range', {
    min: formatInteger(integerRange.min, locale),
    max: formatInteger(integerRange.max, locale),
  });
};

export function isInteger(value: string) {
  return /^-?\d+$/.test(value);
}
