import {
  addDays,
  addHours,
  addMinutes,
  addSeconds,
  compareAsc,
  differenceInCalendarDays,
  format,
  formatRelative,
  isWithinInterval,
} from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { enUS, fr } from 'date-fns/locale';
import { TFunction } from 'i18next';

import { StoreRegion } from '../types/generated';

export const getStoreTimezone = (storeId: StoreRegion) => {
  return storeId === StoreRegion.Us ? 'America/New_York' : 'Europe/Paris';
};

export const formatDate = (date: Date, dateFormat: string, locale: string) => {
  return format(date, dateFormat, {
    locale: locale.startsWith('fr') ? fr : enUS,
  });
};

export const formatDateWithHour = (date: Date, locale: string) => {
  return format(date, `P ${locale.startsWith('fr') ? "'à'" : "'at'"} p`, {
    locale: locale.startsWith('fr') ? fr : enUS,
  });
};

export const generateWeek = (
  firstDayOfWeek: Date,
  saleSelection: { start: Date; end: Date },
  setupSelection: { start: Date; end: Date }
) => {
  return (Array(7) as number[])
    .fill(0)
    .map((x, y) => x + y)
    .map((index) => {
      const currentDay = addDays(firstDayOfWeek, index);
      return {
        date: currentDay,
        isInSaleSelection: isWithinInterval(currentDay, saleSelection),
        isInSetupSelection: isWithinInterval(currentDay, setupSelection),
      };
    });
};

export const formatDateWithoutYear = (date: Date, locale: string) => {
  return format(date, locale.startsWith('fr') ? 'ccc d MMMM' : 'ccc, MMMM d', {
    locale: locale.startsWith('fr') ? fr : enUS,
  });
};

export const formatRelativeDay = (
  date: Date,
  baseDate: Date,
  locale: string
) => {
  const rtf = new Intl.RelativeTimeFormat(locale, { style: 'long' });
  return rtf.format(differenceInCalendarDays(date, baseDate), 'day');
};

export const formatRelativeDateSimplified = (
  date: Date,
  baseDate: Date,
  locale: string
) => {
  return compareAsc(
    addDays(new Date(baseDate.toDateString()), -1),
    new Date(date.toDateString())
  ) <= 0
    ? formatRelative(date, baseDate, {
        locale: locale.startsWith('fr') ? fr : enUS,
        weekStartsOn: 1,
      })
    : format(date, 'P', {
        locale: locale.startsWith('fr') ? fr : enUS,
      });
};

export const formatDateWithSimplifiedMonth = (date: Date, locale: string) => {
  return formatDate(
    date,
    locale.startsWith('fr') ? 'd MMM yy' : 'MMM d, yy',
    locale
  );
};

export const convertLocaleDateToUtc = (date: Date, timeZone: string) => {
  return fromZonedTime(date, timeZone);
};

export const convertUtcToLocaleDate = (date: string, storeId: StoreRegion) => {
  return toZonedTime(date, getStoreTimezone(storeId));
};

export const setLastMinuteOfDay = (date: Date) => {
  let newDate = new Date(date);
  newDate = addHours(newDate, 23);
  newDate = addMinutes(newDate, 59);
  newDate = addSeconds(newDate, 59);

  return newDate;
};

export const formatMonth = (date: Date, locale: string) => {
  return format(date, 'MMMM', {
    locale: locale.startsWith('fr') ? fr : enUS,
    weekStartsOn: 1,
  });
};

export const formatDay = (date: Date, locale: string) => {
  return format(date, 'EEEE d', {
    locale: locale.startsWith('fr') ? fr : enUS,
    weekStartsOn: 1,
  });
};

export const formatDayAndMonth = (date: Date, locale: string) => {
  return format(date, locale.startsWith('fr') ? 'dd/MM' : 'MM/dd', {
    locale: locale.startsWith('fr') ? fr : enUS,
  });
};

const formatRange = (startDate: Date, endDate: Date, locale: string) => {
  const dateTimeFormat = new Intl.DateTimeFormat(locale, {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });
  return dateTimeFormat.formatRange(startDate, endDate);
};

export const formatRangeOrDefault = (
  startDate: string | undefined,
  endDate: string | undefined,
  locale: string
) => {
  if (startDate && endDate)
    return formatRange(new Date(startDate), new Date(endDate), locale);

  const dateTimeFormat = new Intl.DateTimeFormat(locale, {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });
  if (startDate) return `${dateTimeFormat.format(new Date(startDate))} – ...`;
  if (endDate) return `... – ${dateTimeFormat.format(new Date(endDate))}`;
};

export const formatDeliveryDelaysRange = (
  deliveryDelaysRange: { min: number; max: number } | null,
  t: TFunction<'translation', undefined>
) => {
  if (!deliveryDelaysRange) {
    return '-';
  }

  if (deliveryDelaysRange.max === deliveryDelaysRange.min) {
    return t('days', { count: deliveryDelaysRange.min });
  }

  return t('days_range', {
    min: deliveryDelaysRange.min,
    max: deliveryDelaysRange.max,
  });
};

export const truncateTime = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};
