import { atom } from 'recoil';

import { StoreRegion } from '../types/generated';

export const storeRegionState = atom<StoreRegion>({
  key: 'storeRegionState',
  default: new Promise<StoreRegion>((resolve) => {
    const searchParams = new URLSearchParams(document.location.search);
    if (searchParams.has('region')) {
      resolve(searchParams.get('region') as StoreRegion);
    }
    resolve(StoreRegion.Fr);
  }),
});
