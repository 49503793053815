import cn from '@appchoose/cn';

const CommandBadges = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn('m-3 mb-0 flex items-center space-x-2', className)}
      // eslint-disable-next-line react/no-unknown-property
      cmdk-linear-badge=""
      {...props}
    />
  );
};

CommandBadges.displayName = 'CommandBadges';

const CommandBadge = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        'flex w-fit rounded bg-gray-50 px-2 text-xs leading-6 text-gray-900',
        className
      )}
      {...props}
    />
  );
};

CommandBadge.displayName = 'CommandBadge';

export { CommandBadge, CommandBadges };
