import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Button from '@appchoose/button';
import Illustration from '@appchoose/illustration';
import Loader from '@appchoose/loader';
import { Sidebar, SidebarContent, SidebarTrigger } from '@appchoose/sidebar';
import { toast } from '@appchoose/toast';

import { SaleAvatarGroup } from '../../components/sale-avatar-group/sale-avatar-group';
import { useCopyToClipboard } from '../../hooks/use-copy-to-clipboard';
import { useDocumentTitle } from '../../hooks/use-document-title';
import { titleWrapper } from '../../utils/title';
import { BrandDetails } from './brand-details/brand-details';
import { useBrand } from './use-brand';

export const BrandPage: React.FC = () => {
  const { data: brand, isLoading } = useBrand();
  const [brandIdClipboardState, brandIdToClipboard] = useCopyToClipboard();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  useDocumentTitle(brand?.name ?? '', {
    wrapper: titleWrapper,
  });

  useEffect(() => {
    const { value, error } = brandIdClipboardState;
    if (value) {
      toast.success(t('brand_page.copy_brand_id.success'));
    }
    if (error) {
      toast.error(t('brand_page.copy_brand_id.error'));
    }
  }, [brandIdClipboardState, t]);

  if (isLoading) {
    return (
      <section className="relative">
        <div className="h-screen space-y-4 p-6 sm:p-14">
          <div className="flex items-center justify-center">
            <Loader className="mt-2 size-8" />
          </div>
        </div>
      </section>
    );
  }

  if (!brand) {
    return (
      <section className="relative">
        <div className="h-screen space-y-4 p-6 sm:p-14">
          <div className="flex h-full flex-col items-center justify-center">
            <Illustration illustration="boxEmpty" />
            <h3 className="mt-6 text-3.5xl font-bold">
              {t('brand_page.brand_not_found')}
            </h3>
          </div>
        </div>
      </section>
    );
  }

  const isBrandDetailsOpen =
    searchParams.has('edit') && searchParams.get('edit') === 'true';

  return (
    <section className="relative">
      <div className="space-y-4 p-6 sm:p-14">
        <header className="mx-auto max-w-6xl space-y-4">
          <div className="flex flex-wrap justify-between">
            <h1 className="text-3xl font-bold text-gray-900">{brand.name}</h1>
            <div className="flex items-center space-x-4">
              <SaleAvatarGroup
                saleName={brand.contactName ?? ''}
                logisticsName=""
                setupName=""
              />
              <button
                type="button"
                onClick={() => brandIdToClipboard(brand.id)}
                className="text-sm font-semibold text-green-900"
              >
                {t('brand_page.copy_brand_id_label')}
              </button>
              <Button
                type="button"
                onClick={() => {
                  const newSearchParams = new URLSearchParams(searchParams);
                  newSearchParams.set('edit', 'true');
                  setSearchParams(newSearchParams);
                }}
              >
                {t('brand_page.edit_brand')}
              </Button>
            </div>
          </div>
        </header>
        <div className="mx-auto max-w-6xl rounded-2xl border border-gray-100 py-12">
          <header className="mx-8 mb-8 flex flex-wrap items-center justify-between border-b border-gray-100 pb-7 md:mx-10">
            <h2 className="space-x-1 text-2xl font-bold text-gray-900">
              {t('brand_page.informations')}
            </h2>
          </header>
          <div className="mx-8 md:mx-10">
            <dl className="space-y-6">
              <div className="space-y-2">
                <dt className="flex items-center text-xs font-semibold uppercase tracking-wider text-gray-700">
                  {t('brand_page.instagram')}
                </dt>
                <dd>
                  <a
                    href={`https://instagram.com/${brand.instagram}`}
                    target="_blank"
                    rel="noreferrer"
                    className="font-semibold text-green-900"
                  >
                    @{brand.instagram}
                  </a>
                </dd>
              </div>
              <div className="space-y-2">
                <dt className="flex items-center text-xs font-semibold uppercase tracking-wider text-gray-700">
                  {t('brand_page.description')}
                </dt>
                <dd className="whitespace-pre-line">{brand.about}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <Sidebar
        open={isBrandDetailsOpen}
        onOpenChange={() => {
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.delete('edit');
          setSearchParams(newSearchParams);
        }}
        modal={false}
      >
        {/* Sidebar overlay when modal={false} */}
        {isBrandDetailsOpen ? (
          <SidebarTrigger
            className="fixed inset-0 z-40 bg-black/30 data-[state=closed]:duration-300 data-[state=open]:duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
            data-state={isBrandDetailsOpen ? 'open' : 'closed'}
          />
        ) : null}
        <SidebarContent
          className="lg:w-[50rem]"
          // Disable overflow on body when the sidebar is open
          data-no-modal={true}
          // Prevent the sidebar from closing when clicking outside (e.g. on a Chrome extension)
          onPointerDownOutside={(e) => e.preventDefault()}
          onFocusOutside={(e) => e.preventDefault()}
        >
          <BrandDetails brand={brand} />
        </SidebarContent>
      </Sidebar>
    </section>
  );
};
