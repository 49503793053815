import { Node } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    lowercase: {
      lowercase: () => ReturnType;
    };
  }
}

// https://stackoverflow.com/a/62270232
export const Lowercase = Node.create({
  name: 'lowercase',

  addCommands() {
    return {
      lowercase:
        () =>
        ({ tr, state }) => {
          const { selection } = tr;
          const { empty } = selection;

          if (!empty) {
            state.doc.nodesBetween(
              selection.from,
              selection.to,
              (node, position) => {
                // we only processing text, must be a selection
                if (!node.isTextblock || selection.from === selection.to)
                  return;

                // calculate the section to replace
                const startPosition = Math.max(position + 1, selection.from);
                const endPosition = Math.min(
                  position + node.nodeSize,
                  selection.to
                );

                // grab the content
                const substringFrom = Math.max(
                  0,
                  selection.from - position - 1
                );
                const substringTo = Math.max(0, selection.to - position - 1);

                // set the casing
                const updatedText = node.textContent
                  .substring(substringFrom, substringTo)
                  .toLocaleLowerCase();

                // Fix: RangeError: Empty text nodes are not allowed
                if (updatedText.length === 0) return;

                const textNode = state.schema.text(updatedText, node.marks);

                // replace
                tr = tr.replaceWith(startPosition, endPosition, textNode);
              }
            );
          }

          return true;
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Shift-3': () => this.editor.commands.lowercase(),
    };
  },
});
