import { StoreRegion } from '../types/generated';

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isValidHttpUrl = (string: string) => {
  let url;

  try {
    url = new URL(string);
  } catch {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

const SPACE = '\\s+';

export const FR_BLACKLISTED_WORDS = [
  'je',
  'nous',
  'mon',
  'mes',
  'notre',
  'nos',
];
export const US_BLACKLISTED_WORDS = ['we', 'us', 'our', 'I am', 'my', 'me'];

export const getBlacklistedWords = (storeId: StoreRegion | undefined) => {
  return storeId === StoreRegion.Us
    ? US_BLACKLISTED_WORDS
    : FR_BLACKLISTED_WORDS;
};

export const containsBlacklistedWords = (
  valueToCheck: string,
  blacklistedWords: string[]
) => {
  let result = false;
  blacklistedWords.forEach((blacklistedWord) => {
    const regexBetweenSpaces = new RegExp(SPACE + blacklistedWord + SPACE, 'g');
    const regexEndSpace = new RegExp(
      capitalizeFirstLetter(blacklistedWord) + SPACE,
      'g'
    );
    if (
      valueToCheck.toLowerCase().match(regexBetweenSpaces) ||
      valueToCheck.match(regexEndSpace)
    ) {
      result = true;
    }
  });

  return result;
};

export const formatList = (listOfStrings: string[], locale: string) => {
  const formatter = new Intl.ListFormat(locale, {
    style: 'long',
    type: 'conjunction',
  });
  return formatter.format(listOfStrings);
};

export const replaceCount = (string: string, stringToReplaceBy: string) => {
  let count = 0;
  string.replaceAll(stringToReplaceBy, () => {
    count += 1;
    return '1';
  });
  return count;
};

export const getInitials = (name: string) => {
  return name
    .split(' ')
    .map((n) => n[0])
    .slice(0, 2)
    .join('')
    .toUpperCase();
};

export const capitalizeFirstLetterInStringOrList = (text: string) => {
  const trimmedTextToUpdate = text.trimStart();

  let updatedText = capitalizeFirstLetter(text);

  if (trimmedTextToUpdate.startsWith('-')) {
    const indexFirstChar = /[a-zØ-öø-ÿ]/i.exec(trimmedTextToUpdate)?.index;
    if (indexFirstChar && indexFirstChar !== -1) {
      updatedText = `${text.substring(
        0,
        indexFirstChar
      )}${capitalizeFirstLetter(text.substring(indexFirstChar))}`;
    }
  }

  return updatedText;
};

export const sanitizeNewLine = (text: string) => {
  return text.replaceAll('\r\n', '\n');
};

// Shopify prefixes can be found here: https://shopify.dev/changelog/length-of-the-shopify-access-token-is-increasing#:~:text=The%20length%20of%20newly%20generated,tokens%20generated%20after%20this%20date
export const isShopifyTokenValid = (token: string) => {
  if (token.length !== 32 && token.length !== 38) return false;
  if (
    token.length === 38 &&
    !(
      token.startsWith('shpat_') ||
      token.startsWith('shpca_') ||
      token.startsWith('shppa_')
    )
  )
    return false;

  return true;
};

export const isShopifySecretValid = (secret: string) => {
  if (secret.length !== 32 && secret.length !== 38) return false;
  if (secret.length === 38 && !secret.startsWith('shpss_')) return false;

  return true;
};

export const formatShopifyURL = (string: string) => {
  let url: string;
  try {
    const host = new URL(string).host;
    url = host;
  } catch {
    url = string;
  }
  return url;
};
